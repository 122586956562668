import { lazy, Suspense, useState } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

import "./App.css";

import Loading from "./components/Loading";
import Navbar from "./components/Navbar";

const LoginPage = lazy(() => import("./pages/LoginPage"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ListPage = lazy(() => import("./pages/ListPage"));
const New = lazy(() => import("./pages/New"));
const Support = lazy(() => import("./pages/Support"));
const Profile = lazy(() => import("./pages/Profile"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

export default function App() {
  const isAuthenticated = useIsAuthenticated();
  const [isActive, setActive] = useState(false);

  function toggleClass() {
    setActive(!isActive);
  }

  return (
    <>
      {isAuthenticated() ? (
        <Suspense fallback={<Loading />}>
          <Sidebar isActive={isActive} />

          <section id="content" className="ps-4">
            <Navbar toggleClass={toggleClass} />

            <Routes>
              <Route
                exact
                path="/"
                element={
                  <RequireAuth loginPath={"/"}>
                    <Dashboard />
                  </RequireAuth>
                }
              />
              <Route
                path="/:title"
                element={
                  <RequireAuth loginPath={"/"}>
                    <ListPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/:title/new"
                element={
                  <RequireAuth loginPath={"/"}>
                    <New />
                  </RequireAuth>
                }
              />
              <Route
                path="/:title/:id"
                element={
                  <RequireAuth loginPath={"/"}>
                    <New />
                  </RequireAuth>
                }
              />
              <Route
                path="/support"
                element={
                  <RequireAuth loginPath={"/"}>
                    <Support />
                  </RequireAuth>
                }
              />
              <Route
                path="/settings"
                element={
                  <RequireAuth loginPath={"/"}>
                    <Profile />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </section>
        </Suspense>
      ) : (
        <LoginPage />
      )}
    </>
  );
}
