import { Button } from "react-bootstrap";

export default function ErrorBoundary({ resetErrorBoundary }) {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <h2>Oops! Something went wrong.</h2>
      <img
        src="https://api.caafimed.com/uploads/images/error.png"
        alt="Error"
        style={{ width: "300px", height: "300px", objectFit: "cover" }}
      />
      <p>
        Uh-oh! Something went wrong while trying to show this content. Don't
        worry; it seems like there's a network error. Please check your internet
        connection and try again shortly.
      </p>
      <Button onClick={resetErrorBoundary}>Go to Home</Button>
    </div>
  );
}
