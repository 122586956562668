import Spinner from "react-bootstrap/Spinner";

function Loading() {
  return (
    <div
      className="position-fixed top-0 start-0 bottom-0 end-0 absolute fixed bg-light vh-100 vw-100 d-flex justify-content-center align-items-center"
      style={{zIndex:"9999"}}
    >
      <Spinner animation="grow" variant="success" />
    </div>
  );
}

export default Loading;
