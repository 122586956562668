import { LuMenu } from "react-icons/lu";
import { Image } from "react-bootstrap";
import { MdOutlineSettings, MdLogout } from "react-icons/md";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";

import Avatar from "../assets/images/avatar.png";

export default function Navbar({ toggleClass }) {
  const signOut = useSignOut();
  const navigate = useNavigate();

  return (
    <nav>
      <i className="fs-2" onClick={toggleClass} style={{ cursor: "pointer" }}>
        <LuMenu />
      </i>

      <div className="d-flex gap-3 align-items-center ms-auto">
        <Dropdown className="profile">
          <Dropdown.Toggle
            style={{ border: "none", padding: "0", zIndex: "1000" }}
            className="bg-transparent"
            id="dropdown-basic"
          >
            <Image src={Avatar} alt="logo" loading="lazy" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <LinkContainer to="/settings">
              <Dropdown.Item>
                <MdOutlineSettings /> Settings
              </Dropdown.Item>
            </LinkContainer>

            <Dropdown.Item
              onClick={(e) => {
                signOut();
                navigate("/");
              }}
            >
              <MdLogout />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  );
}
